<template>
  <!-- Desktop -->
  <div>
    <md-dialog :md-active.sync="emergency">
      <md-dialog-title style="font-size: 2em; color: red; font-weight: bold"
        >Emergency</md-dialog-title
      >

      <md-dialog-content style="overflow: hidden">
        <div v-bind:class="{ emergency: !myWidth, emergencyMobile: myWidth }">
          Are you in an emergency situation?<br />
          <span
            >Call your local
            <a
              target="_blank"
              href="https://en.wikipedia.org/wiki/List_of_emergency_telephone_numbers"
            >
              emergency number</a
            > </span
          ><br /><br />
          Suicide and emotional support hotline: 116 123<br /><br />
          Suicide chat:
          <a target="_blank" href="https://www.samaritans.org/"
            >samaritans.org</a
          ><br /><br />
          Suicide hotline for every country:<br /><br />
          <ul>
            <li>
              <a
                target="_blank"
                href="http://www.suicide.org/international-suicide-hotlines.html"
                >suicide.org</a
              >
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.therapyroute.com/article/helplines-suicide-hotlines-and-crisis-lines-from-around-the-world"
                >therapyroute.com</a
              >
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.opencounseling.com/suicide-hotlines"
                >opencounseling.com</a
              >
            </li>
          </ul>
        </div>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button
          class="md-primary"
          @click="emergency = false"
          style="font-size: 1.3em; max-width: 1280px"
          >{{ getContent(55) }}</md-button
        >
      </md-dialog-actions>
    </md-dialog>
    <md-dialog :md-active.sync="shop">

      <md-dialog-content style="overflow: hidden">
       <a href="https://shorturl.at/fgOPS" target="_blank"><img src="@/assets/shop_popup.png" alt="Mind Support Shop launched"></a>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button
            class="md-primary"
            @click="closeShopPopup()"
            style="font-size: 1.3em; max-width: 1280px"
        >{{ getContent(55) }}</md-button
        >
      </md-dialog-actions>
    </md-dialog>

    <md-dialog :md-active.sync="ineligibility">
      <md-dialog-content style="overflow: hidden">
        <div
          v-bind:class="{
            ineligibility: !myWidth,
            ineligibilityMobile: myWidth,
          }"
        >
          Hi there,<br />
          <br />
          Unfortunately we have only built our platform for certain mental
          health difficulties so far and are unable to offer you peer support at
          this time. We’re sorry about this.<br />
          <br />
          If you are in an emergency situation please call
          <a
            target="_blank"
            href="https://en.wikipedia.org/wiki/List_of_emergency_telephone_numbers"
            >your local emergency number</a
          >
          or the mental health hotline: 116 123<br />
          <br />
          What can we offer you?<br />
          Check out our
          <a target="_blank" href="support/self">self support section</a> where
          you can learn more about mental illnesses, coping strategies and
          lifestyle tips. There is scientific evidence suggesting that increased
          mental health literacy is associated with increased mental
          wellbeing.<br />
          <br />
          Please don’t hesitate to contact us if you have any questions or
          feedback:
          <a target="_blank" href="mailto:info@mindsupport.eu"
            >info@mindsupport.eu</a
          ><br />
          <br />
          Warm regards,<br />
          <br />
          Your Mind Support Team
          <br />
        </div>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button
          class="md-primary"
          @click="ineligibility = false"
          style="font-size: 1.3em; max-width: 1280px"
          >{{ getContent(55) }}</md-button
        >
      </md-dialog-actions>
    </md-dialog>

    <md-dialog :md-active.sync="donate" @md-closed="reload()">
      <md-dialog-content>
        <div class="Donation">
          <div>
            <div
              id="MindSupport"
              class="widget"
              value="donation-form+widget"
              data-slug="MindSupport"
              data-lang="en"
              data-card="show"
              data-success_url=""
              data-fail_url=""
            ></div>
          </div>
        </div>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button
          class="md-primary"
          @click="donate = false"
          style="font-size: 1.3em"
          >{{ getContent(55) }}</md-button
        >
      </md-dialog-actions>
    </md-dialog>

    <div v-if="!myWidth">
      <div class="container1 flexRow">
        <img
          class="img"
          @click="link({ name: 'Landing' })"
          src="@/assets/logo.jpeg"
          width="15%"
        />
        <div class="menu">
          <div
            class="menuItem"
            @click="linkandscroll({ name: 'Landing' }, '#aboutHeader')"
          >
            About
          </div>
          <!--          <div class="menuItem" @click="link('home#About')">-->
          <!--            {{ getContent(59) }}-->
          <!--          </div>-->
          <!--          <div class="menuItem" @click="link('home#Team')">-->
          <!--            {{ getContent(1320) }}-->
          <!--          </div>-->
          <div
            class="menuDropdown"
            @mouseover="PeerSupport = true"
            @mouseleave="PeerSupport = false"
          >
            <div class="menuItem" @click="link({ name: 'Peer Support' })">
              Peer Support
            </div>
            <ul v-if="PeerSupport" @click="listOne = false">
              <li
                class="menuItem"
                @click="link({ name: 'Questions', query: { state: 1 } })"
              >
                Receive Support
              </li>
              <li
                class="menuItem"
                @click="link({ name: 'Questions', query: { state: 0 } })"
              >
                Support others
              </li>
              <li
                class="menuItem"
                @click="link({ name: 'Questions', query: { state: 2 } })"
              >
                Mutual Support
              </li>
            </ul>
          </div>
          <div
            class="menuDropdown"
            @mouseover="SelfSupport = true"
            @mouseleave="SelfSupport = false"
          >
            <div class="menuItem" @click="link({ name: 'Self Support' })">
              Self Support
            </div>
            <ul v-if="SelfSupport" @click="listOne = false">
              <li
                class="menuItem"
                @click="
                  link({
                    name: 'Self Support Content',
                    params: { kind: 'knowledge' },
                  })
                "
              >
                Knowledge
              </li>
              <li
                class="menuItem"
                @click="
                  link({
                    name: 'Self Support Content',
                    params: { kind: 'practice' },
                  })
                "
              >
                Practice
              </li>
              <li
                class="menuItem"
                @click="link({ name: 'Recommendation - Self Support' })"
              >
                Recommendations
              </li>
              <li
                class="menuItem"
                @click="
                  link({
                    name: 'Self Support Content',
                    params: { kind: 'viewall' },
                  })
                "
              >
                View All
              </li>
            </ul>
          </div>
          <div class="menuItem" @click="(donate = true), loadScript()">
            {{ getContent(61) }}
          </div>
          <div class="menuItem" @click="openShop()">
            Shop
        </div>
          <div class="menuItem" style="color: red" @click="emergency = true">
            {{ getContent(57) }}
          </div>
          <div
            class="menuItem"
            @click="link({ name: 'Signup' })"
            v-if="!isLoggedIn()"
          >
            SIGN UP
          </div>
          <div class="signInFrame">
            <div
              class="signIn menuItem"
              @click="link({ name: 'Signin' })"
              v-if="!isLoggedIn()"
            >
              {{ getContent(56) }}
            </div>
            <div
              class="signIn menuItem"
              @click="link({ name: 'Dashboard' })"
              v-if="isLoggedIn()"
            >
              {{ getContent(1356) }}
            </div>
          </div>
          <div v-if="this.$route.name == 'SelfCare'">
            <md-dialog :md-active.sync="showDialog">
              <md-dialog-title>{{ getContent(63) }}</md-dialog-title>
              <md-dialog-content>
                <p>
                  {{ getContent(64) }}
                </p>
                <p>
                  {{ getContent(65) }}
                </p>
                <p>
                  {{ getContent(66) }}
                </p>
              </md-dialog-content>
              <md-dialog-actions>
                <md-button class="md-primary" @click="showDialog = false"
                  >Close</md-button
                >
              </md-dialog-actions>
            </md-dialog>

            <div v-on:click="showDialog = true">
              <md-icon class="md-size-3x">info</md-icon>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile -->
    <div v-if="myWidth">
      <div class="container1Mobile flexRow">
        <img
          class="img"
          @click="link({ name: 'Landing' })"
          src="@/assets/logo.jpeg"
          width="40%"
        />
        <div class="menu">
          <md-menu
            md-size="medium"
            md-align-trigger
            @md-opened="isOpen = true"
            @md-closed="isOpen = false"
            v-if="this.$route.name !== 'SelfCare'"
          >
            <md-button md-menu-trigger>
              <div v-if="!isOpen">
                <md-icon class="md-size-2x" style="color: #000000"
                  >menu</md-icon
                >
              </div>
              <div v-if="isOpen">
                <md-icon class="md-size-2x" style="color: #000000"
                  >close</md-icon
                >
              </div>
            </md-button>
            <md-menu-content>
              <md-menu-item
                @click="link({ name: 'Signin' })"
                v-if="!isLoggedIn()"
                >{{ getContent(56) }}</md-menu-item
              >
              <md-menu-item
                @click="link({ name: 'Dashboard' })"
                v-if="isLoggedIn()"
                >{{ getContent(1356) }}</md-menu-item
              >
              <md-menu-item
                @click="link({ name: 'Signup' })"
                v-if="!isLoggedIn()"
              >
                SIGN UP
              </md-menu-item>
              <md-menu-item @click="link({ name: 'Peer Support' })">
                PEER SUPPORT
              </md-menu-item>
              <md-menu-item @click="link({ name: 'Self Support' })">
                SELF SUPPORT
              </md-menu-item>
              <md-menu-item @click="emergency = true">{{
                getContent(57)
              }}</md-menu-item>
              <md-menu-item @click="(donate = true), loadScript()">{{
                getContent(61)
              }}</md-menu-item>
              <!--              <md-menu-item @click="link('home#Team')">{{-->
              <!--                getContent(1320)-->
              <!--              }}</md-menu-item>-->
              <!--              <md-menu-item @click="link('home#About')">{{-->
              <!--                getContent(59)-->
              <!--              }}</md-menu-item>-->
            </md-menu-content>
          </md-menu>
          <div v-if="this.$route.name == 'SelfCare'">
            <md-dialog :md-active.sync="showDialog">
              <md-dialog-title>{{ getContent(63) }}</md-dialog-title>
              <md-dialog-content>
                <p>
                  {{ getContent(64) }}
                </p>
                <p>
                  {{ getContent(65) }}
                </p>
                <p>
                  {{ getContent(66) }}
                </p>
              </md-dialog-content>
              <md-dialog-actions>
                <md-button class="md-primary" @click="showDialog = false"
                  >Close</md-button
                >
              </md-dialog-actions>
            </md-dialog>

            <div v-on:click="showDialog = true">
              <md-icon class="md-size-3x">info</md-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadData } from "@/utils";
import store from "@/store";
import VueCookie from 'vue-cookies';

export default {
  // eslint-disable-next-line no-undef
  props: {
    language: String,
  },
  data() {
    return {
      isOpen: false,
      showDialog: false,
      myWidth: screen.height / screen.width > 1,
      myLanguage: this.$router.currentRoute.params.language.toUpperCase(),
      emergency: false,
      ineligibility: false,
      therapists: false,
      donate: false,
      PeerSupport: false,
      SelfSupport: false,

      shop: false,

      text: {},
    };
  },
  methods: {
    mail: function (l) {
      window.location = l;
    },
    openShop(){
      window.open('https://shorturl.at/fgOPS')
    },
    link: function (l) {
      //   if(typeof(l)==='string' && l.includes('#')){
      //     this.$router.push(l.split('#')[0]).then(() => this.$router.push(l))
      // }
      this.$router.push(l);
    },
    getContent: function (key) {
      return this.text[key].toUpperCase();
    },
    onSelect(item) {
      this.$router.push("/" + item + "/home");
    },
    isLoggedIn: function () {
      store.dispatch("fetchAccessToken");
      return store.state.accessToken != null;
    },
    loadScript: function () {
      let script = document.createElement("script");
      script.setAttribute(
        "src",
        "https://res.cloudinary.com/dxhaja5tz/raw/upload/script_main.js"
      );
      document.head.appendChild(script);
    },
    reload: function () {
      location.reload();
    },
    linkandscroll: function (l, div) {
      /* The smooth scrolling animation messes up when coming from a different page.
      The following makes sure the page only uses the smooth animation when the user
      is already on the landing page.*/
      var scrollBehavior;
      if (window.location.href.split("/").includes("home")) {
        scrollBehavior = "smooth";
      } else {
        scrollBehavior = "auto";
      }
      document;
      this.$router.push(l).finally(() => {
        this.scroll(div, scrollBehavior);
      });
    },
    scroll: function (div, scrollBehavior) {
      /* Note that, for the reason stated above, this scroll functions takes an extra 
      argument declaring the scrolling behavior. Smooth looks nicer, but has some 
      issues.*/
      document
        .getElementById(div.substring(1))
        .scrollIntoView({ block: "start", behavior: scrollBehavior });
    },
    closeShopPopup(){
      this.shop = false
      VueCookie.set('shop', 'closed', '2m');
    }
  },
  async created() {
    this.text = await loadData("navbar", this.myLanguage);
    const anchor = this.$router.currentRoute.hash;
    if (anchor == "#QuestionsEmergency")
      (this.emergency = true), (this.ineligibility = true);
    if(new Date() < new Date('2023-01-01') && !VueCookie.get('shop')){
        this.shop = true
    }
  },
  name: "NavBar",
};
</script>

<style scoped>
.container1,
.container1Mobile {
  background: #ffffff;
  color: #000;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
}
.container1Mobile {
  align-items: center;
}
.menu {
  font-size: 1.6vw;
  color: #000000;
  margin-right: 1%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.menuItem {
  margin: 2.3vw 1vw 1vw 1vw;
  font-family: var(--h3);
  text-transform: uppercase;
}
.menuItem:hover {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}
.md-button {
  font-size: 70%;
  font-weight: inherit;
}
.img {
  padding: 10px 0px 10px 10px;
  transform: rotate(2deg);
}
.img:hover {
  cursor: pointer;
}
.md-dialog /deep/.md-dialog-container {
  max-width: 768px;
}
p {
  padding-left: 2%;
  padding-right: 2%;
}
.mobile {
  width: 25vw;
  margin: 0;
}
.Desktop {
  width: 7vw;
  margin: 0;
  min-height: 0;
  padding: 0;
  padding-left: 5px;
}
.signIn {
  background: #4f83ab;
  color: #ffffff;
}
.signInFrame {
  background: #4f83ab;
  border-radius: 0 0 25px 25px;
}
.ineligibility,
.ineligibilityMobile,
.emergency,
.emergencyMobile {
  font-size: 1.1em;
  color: #000000;
  word-wrap: break-word;
  overflow: hidden;
}
.emergency {
  width: 50vw;
}
.emergencyMobile {
  width: auto;
}
.Donation {
  display: flex;
  align-items: center;
  justify-content: center;
}
.menuDropdown {
  position: relative;
  display: flex;
  z-index: 10;
}
.menuDropdown ul {
  position: absolute;
  left: 0;
  top: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}
.menuDropdown ul li {
  text-align: left;
  white-space: nowrap;
  color: #000000;
  margin: 0 0 0.5vw 1vw;
}
</style>
