<template>
  <div>
    <nav-bar :language="language" class="navbar"/>
    <router-view class="main"/>
    <footer-imprint class="footer"/>
  </div>
</template>
<script>
import navBar from '@/components/NavBar'
import FooterImprint from "@/components/Footer.vue"
export default {
  name: "Language_Container",
  data(){
    return{
      language: 'EN'
    }
  },
  created() {
    if(this.$router.currentRoute.params.language == null)
      this.$router.push('/en/home')
    else
      this.language = this.$router.currentRoute.params.language.toUpperCase()
  },
  components: {
    navBar,
    FooterImprint
  }
}
</script>
<style scoped>
.navbar{
}
.main{
  min-height: 92vh;
}
.footer{
  height:2.5vh
}
</style>
